import React, { useEffect, useState, useContext } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import { useCreateResourceMutation } from '../api/apiSlice';
import ReactLoading from 'react-loading';

import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { API_KEY } from './../config';

// Create a mapping of icon names to components
const iconMapping = {
  FaFacebookF: FaFacebookF,
  FaInstagram: FaInstagram,
  FaYoutube: FaYoutube,
  FaLinkedinIn: FaLinkedinIn,
  FaTiktok: FaTiktok,
  FaTwitter: FaTwitter
};

export const Footer = () => {
  const { texts, language } = useContext(LanguageContext);
  const [createResource, { error, isLoading }] = useCreateResourceMutation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const createdData = await createResource({
          url: `/get`,
          body: {
            procedureName: "SPSiteSocialMedia_Select",
            params: `6`,
            lang: "",
            apiKey:API_KEY,
          },
        });

        setData(createdData?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [language, createResource]);

  if (data?.length === 0) {
    return (
      <div className='w-full h-screen absolute bg-white flex justify-center items-center'>
        <ReactLoading type={'bars'} height={50} width={50} color='#12' />
      </div>
    );
  }

  return (
    <footer id='footer' className="p-4 bg-slate-800 md:pr-6 md:pl-6 md:pb-4 md:py-8 w-full m-auto text-white" dir='ltr'>
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-white sm:text-center">
          ©{(new Date().getFullYear())} <a target="_blank" href="/" className="hover:underline">Srwshti Nishtiman Company™</a>. All Rights Reserved.
        </span>
        <ul className="md:flex md:flex-row flex-col md:items-center items-start mt-3 text-sm font-medium text-white sm:mt-0">
          <h1 className='md:mb-0 mb-2 mr-0 md:mr-2'>Follow Us</h1>
          <div className='flex'>
            {
              data?.map((item, index) => {
                const IconComponent = iconMapping[item.Icon];
                const hoverClass = `hover:${item.Icon.toLowerCase()}-color`; // Assuming each icon has a corresponding hover class
                return (
                  <li key={index} className='flex justify-center items-center cursor-pointer'>
                    {IconComponent && (
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`${item.link}`}
                        className={`${hoverClass} mr-2`}
                      >
                        <IconComponent />
                      </a>
                    )}
                  </li>
                );
              })
            }
          </div>
        </ul>
      </div>
    </footer>
  );
};

// Example CSS (Add this to your CSS file or in a <style> tag)
const styles = `
  .hover\\:fafacebookf-color:hover { color: #4267B2; } /* Facebook */
  .hover\\:fainstagram-color:hover { color: #C13584; } /* Instagram */
  .hover\\:fayoutube-color:hover { color: #FF0000; } /* YouTube */
  .hover\\:falinkedinin-color:hover { color: #0077B5; } /* LinkedIn */
  .hover\\:fatiktok-color:hover { color: #69C9D0; } /* TikTok */
  .hover\\:fatwitter-color:hover { color: #1DA1F2; } /* Twitter */
`;

// Append the styles to the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
