import React, { useEffect, useState, useContext } from "react";
import { LanguageContext } from "../Context/LanguageContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactModal from "react-modal";
import { useCreateResourceMutation } from "../api/apiSlice";
import ReactLoading from 'react-loading';
import { FaPlayCircle } from 'react-icons/fa';
import { API_KEY } from './../config';

ReactModal.setAppElement('#root'); // Make sure to set the root element

function VideosSection() {
  const { texts, language } = useContext(LanguageContext);
  const [createResource, { error, isLoading }] = useCreateResourceMutation();
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(-1);
  const [isOpen, setModalOpen] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const createdData = await createResource({
          url: `/get`,
          body: {
            procedureName: "SPSite_SelectCategory_DESC",
            params: `20`,
            lang: language === 'en' ? 2 : (language === 'ar' ? 3 : 1),
            apiKey: API_KEY,
          },
        });

        setData(createdData?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [language, createResource]);

  if (data?.length === 0) {
    return (
      <div className='w-full h-screen absolute bg-white flex justify-center items-center'>
        <ReactLoading type={'bars'} height={50} width={50} color='#12' />
      </div>
    );
  }

  const videos = data?.map((item) => {
    const videoId = item.Embed.split('embed/')[1].split('?')[0];
    return {
      url: `${item.Embed}`,
      thumbnail: item.Photo === 'root/images/defaultPost.png' 
        ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
        : `https://srwshtinistimanstorage.blob.core.windows.net/root/${item.Photo}`,
      title: item?.Title
    };
  });

  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    slidesPerRow: 1,
    initialSlide: 2,
    autoplay: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const openModal = (index) => {
    setIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div
      id="videos"
      className="flex flex-col max-w-[1300px] m-auto items-center justify-center mt-10 pb-10 text-center pr-4 pl-4 sm:mb-56 mb-40"
    >
      <h1
        className="text-black font-bold sm:text-3xl text-lg"
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        {texts.our_videos}
      </h1>

      <hr
        className="border-primary w-20 mt-3 mb-7"
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      />


      <div className="w-[95%] h-44 relative">
        <Slider {...settings}>
        {videos.map((item, i) => (
  <div key={i} className="card w-full relative">
    <div
      className="flex flex-col justify-center items-center h-64 relative"
      onClick={() => openModal(i)}
    >
      <img
        src={item.thumbnail}
        alt={`Thumbnail for video ${i + 1}`}
        className="object-cover w-full h-64 cursor-pointer"
      />
      {/* Adjust the positioning of the play icon */}
      <FaPlayCircle className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                               text-white text-4xl cursor-pointer opacity-100
                                border-primary rounded-full border-2 h-[60px] w-[60px]"  />
    </div>
    <div className="mt-2 mb-2 text-white text-center">
      <p className="font-semibold mr-2 ml-2">{item.title}</p>
    </div>
  </div>
))}
        </Slider>
      </div>

      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <button onClick={closeModal} className="close-button">✖</button>
        {index >= 0 && (
          <iframe
            src={`${videos[index].url}&autoplay=1`}
            title={`Video ${index + 1}`}
            className="object-cover w-full h-full"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </ReactModal>
    </div>
  );
}

export default VideosSection;
