import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';

function BrandIcon({ text, icon , subtitle }) {

  const handleClick = () => {};

  return (
    <div onClick={handleClick} className='flex flex-col items-center justify-between mb-5 md:w-60 w-28 cursor-pointer mr-5 '>
      {icon.endsWith('.png') ? (
        <img
        className={`md:w-24 w-14 md:h-24 h-14`}
        data-aos='fade-down'
        src={icon}
        alt="icon"
      />
      ) : (
        <img
          className={`md:w-40 w-20 md:h-40 h-20`}
          data-aos='fade-down'
          src={icon}
          alt="icon"
        />
      )}


     {icon.endsWith('.png') ? ( <h1
        className='text-black md:text-3xl font-bold mt-5'
        data-aos='fade-down'
      >
        {text}
      </h1>):<h1
        className='text-black md:text-xl font-semibold mt-5'
        data-aos='fade-down'
        dangerouslySetInnerHTML={{ __html: text }}
      />}


      {icon.endsWith('.png') ? ( <h1
        className='text-black md:text-lg font-semibold mt-5'
        data-aos='fade-down'
      >
        {subtitle}
      </h1>):null}
    </div>
  );
}

export default BrandIcon;
