import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import { useContext } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';
import BrandIcon from '../components/BrandIcon';
import { useCreateResourceMutation } from '../api/apiSlice';
import ReactLoading from 'react-loading';
import { API_KEY } from './../config';

function Brands() {
  const { texts, language } = useContext(LanguageContext);

  const [createResource, { error, isLoading }] = useCreateResourceMutation();
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const createdData = await createResource({
          url: `/get`,
          body: {
            procedureName: "SPSite_Info",
            params: `-1`,
            lang: language === 'en' ? 17 : (language === 'ar' ? 60 : 63),
            apiKey: API_KEY,
          },
        });

        setData(createdData?.data?.data);

        const createdDataDetail = await createResource({
          url: `/get`,
          body: {
            procedureName: "SPSite_SelectCategory_DESC",
            params: `6`,
            lang: language === 'en' ? 2 : (language === 'ar' ? 3 : 1),
            apiKey: API_KEY,
          },
        });

        setDataDetail(createdDataDetail?.data?.data);
        console.log(dataDetail, "Data Detail:", createdDataDetail?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [language, createResource]);

  if (data?.length === 0) {
    return (
      <div className='w-full h-screen absolute bg-white flex justify-center items-center'>
        <ReactLoading type={'bars'} height={50} width={50} color='#12' />
      </div>
    );
  }

  return (
    <div id='brands' className='flex flex-col max-w-[1300px] mt-20 m-auto items-center justify-center pb-10 text-center pr-4 pl-4'>
      <h1
        className="text-black font-bold sm:text-3xl text-lg"
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        dangerouslySetInnerHTML={{ __html: (!error ? data[0]?.title : '') }}
      />
      <hr
        className="border-primary w-20 mt-3 mb-7"
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
      />
      <h1
        className="text-black font-semibold sm:text-lg text-sm mb-14"
        data-aos="fade-down"
        dangerouslySetInnerHTML={{ __html: (!error ? data[0]?.Detail : '') }}
      />

      <div className='w-full flex md:flex-row flex-wrap justify-center items-start mb-14 '>
        {dataDetail.map((item, i) => (
          <BrandIcon key={i} text={item.Title} icon={`https://srwshtinistimanstorage.blob.core.windows.net/root/${item.Photo}`} />
        ))}
      </div>
    </div>
  );
}

export default Brands;
