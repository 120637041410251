import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "../api/apiSlice"
import resourcesSlice from "../api/resourcesSlice"

export const store = configureStore({
    reducer: {
        resources: resourcesSlice,
        [apiSlice.reducerPath]: apiSlice.reducer
    },

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})