import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import { useContext } from 'react';
import 'aos/dist/aos.css';
import { useCreateResourceMutation } from '../api/apiSlice';
import ReactLoading from 'react-loading';
import ContactInfo from '../components/ContactInfo';
import { FaLocationDot } from "react-icons/fa6";
import { API_KEY } from './../config';


function Contact() {
  const { texts, language } = useContext(LanguageContext);

  const [createResource, {error}, isLoading] = useCreateResourceMutation();


  const [data,setData] = useState([]);
  const [dataDetail,setDataDetail] = useState([]);


 
  useEffect(() => {
    const fetchData = async () => {
      try {
       const createdData = await createResource({ url: `/get`, body: {
          "procedureName": "SPSite_Info",
          "params": `-1`,
          "lang": language == 'en' ? 4 : (language == 'ar' ? 54 : 45),
          "apiKey": API_KEY,
        } });
        
         setData(createdData?.data?.data);

         const createdDataDetail = await createResource({ url: `/get`, body: {
          "procedureName": "SPSite_Info",
          "params": `-1`,
          "lang": language == 'en' ? 3 : (language == 'ar' ? 30 : 50),
          "apiKey": API_KEY,
        } });
        
         setDataDetail(createdDataDetail?.data?.data);


      } catch (error) {
        console.log(error);
      }
    };

    
      fetchData();
    
  }, [language]); 

  if (data?.length == 0) {
    return <div classNameName='w-full h-screen absolute bg-white flex  justify-center items-center'>
           <ReactLoading type={'bars'} height={50}  width={50} color='#12'/>
      </div>;
  }

  return (
    <div id='contact' className='flex flex-col bg-gray-200 mt-20 m-auto items-center justify-center pt-10 text-center pr-4 pl-4'>
      <h1
        className="text-black font-bold sm:text-3xl text-lg"
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        dangerouslySetInnerHTML={{ __html: (!error ? data[0]?.title : '') }}
      />

             <hr
                className="border-primary w-20 mt-3 mb-7"
                data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
            />

        <div className='w-full flex md:flex-row flex-col justify-center md:items-start  items-center  max-w-[1300px] mb-10'>
          <ContactInfo text={(!error ? dataDetail[0]?.Detail : '')} icon={'tel'} language={language}/>
          <ContactInfo text={(!error ? dataDetail[0]?.title : '')} icon={'location'} language={language}/>
          <ContactInfo text={(!error ? dataDetail[0]?.Summary : '')} icon={'email'} language={language}/>
        </div>
    </div>
  );
}

export default Contact;
