import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { GoLocation } from "react-icons/go";





function ContactInfo({text,icon,language}) {

  return (
    <div className={`flex flex-col items-center justify-items-center m-4  `}>
         <div className='w-20 h-20 rounded-full flex justify-center items-center bg-white'>
          { icon == 'tel'? <BsTelephone className='h-10 w-10' />
             : (icon == 'email'?<CiMail className='h-10 w-10'/>:<GoLocation className='h-10 w-10'/>)}
         </div>
        <h1 className='text-black text-lg font-medium  mt-5 w-64'    
            dangerouslySetInnerHTML={{ __html: text}}/>
    </div>
  )
}

export default ContactInfo