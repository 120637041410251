import React, { useEffect, useState } from "react";
import { LanguageContext } from "../Context/LanguageContext";
import { useContext } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from "aos";
import "aos/dist/aos.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { useCreateResourceMutation } from "../api/apiSlice";
import ReactLoading from 'react-loading';
import { API_KEY } from './../config';


function Products() {
  const { texts, language } = useContext(LanguageContext);

  const [createResource, { error, isLoading }] = useCreateResourceMutation();
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);

  const [index, setIndex] = useState(-1);
  const [isOpen, setModalOpen] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const createdData = await createResource({
          url: `/get`,
          body: {
            procedureName: "SPSite_Info",
            params: `-1`,
            lang: language === 'en' ? 10 : (language === 'ar' ? 56 : 62),
            apiKey: API_KEY,
          },
        });

        setData(createdData?.data?.data);

        const createdDataDetail = await createResource({
          url: `/get`,
          body: {
            procedureName: "SPSite_SelectCategory_DESC",
            params: `66`,
            lang: language === 'en' ? 2 : (language === 'ar' ? 3 : 1),
            apiKey: API_KEY,
          },
        });

        setDataDetail(createdDataDetail?.data?.data);
        console.log(dataDetail, "Data Detail:", createdDataDetail?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [language, createResource]);

  if (data?.length === 0) {
    return (
      <div className='w-full h-screen absolute bg-white flex justify-center items-center'>
        <ReactLoading type={'bars'} height={50} width={50} color='#12' />
      </div>
    );
  }

  const images = dataDetail.map((item) => ({
    src: `https://srwshtinistimanstorage.blob.core.windows.net/root/${item.Photo}`,
  }));



  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    centerPadding: "60px",
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    slidesPerRow: 1,
    initialSlide: 2,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplaySpeed: 3000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplaySpeed: 3000,
        },
      },
    ],
  };

  const setIsOpen = (state, i) => {
    setIndex(i);
    setModalOpen(state);
  };

  return (
    <div
      id="products"
      className="flex flex-col max-w-[1300px] m-auto items-center justify-center mt-10 pb-10 text-center pr-4 pl-4 sm:mb-56 mb-40"
    >
      <h1
        className="text-black font-bold sm:text-3xl text-lg"
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
        dangerouslySetInnerHTML={{ __html: (!error ? data[0]?.title : '') }}
      />

      <hr
        className="border-primary w-20 mt-3 mb-7"
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      />

      <h1
        className="text-black font-semibold sm:text-lg text-sm mb-14"
        data-aos="fade-down"
        dangerouslySetInnerHTML={{ __html: (!error ? data[0]?.Detail : '') }}
      />

      <div className="w-[95%] h-32 relative">
        <Slider {...settings}>
          {images.map((item, i) => (
            <div key={i} className="card w-full">
              <div
                className="flex justify-center items-center"
                onClick={() => setIsOpen(true, i)}
              >
                <img
                  src={item.src}
                  alt={`Image ${i + 1}`}
                  className="object-cover w-full h-56 cursor-pointer"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      

      <Lightbox
        index={index}
        slides={images}
        open={isOpen}
        plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        close={() => setIsOpen(false)}
        styles={{
          thumbnail: {
            borderColor: "#3498db",
          },
        }}
      />
    </div>
  );
}

export default Products;
