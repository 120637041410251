import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import React from 'react';
import { LanguageProvider } from '../src/Context/LanguageContext';
import { MantineProvider } from "@mantine/core";
import { Provider } from 'react-redux';
import { store } from './store/store.js';
import ErrorBoundary from './ErrorBoundary.jsx';



const root = ReactDOM.createRoot(document.getElementById('root'));  




root.render(
  <React.StrictMode>
   <LanguageProvider>
   <MantineProvider>
     <Provider store={store}>
       <ErrorBoundary>
        <App />
       </ErrorBoundary>
     </Provider>
     </MantineProvider>
   </LanguageProvider>
  </React.StrictMode>
);

