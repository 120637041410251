
import { Routes, Route,Switch } from 'react-router-dom';
import About from '../pages/About';
import Hero from '../pages/Hero';
import AboutSection from '../pages/AboutSection';
import HomeButton from '../components/HomeButton';
import Products from '../pages/products';
import Brands from '../pages/Brands';
import Numbers from '../pages/Numbers';
import Location from '../pages/Location';
import VideosSection from '../pages/VideosSection';
import Contact from '../pages/Contact';
import InternalServerError from '../500';
import NotFound from '../404';
const AppRoutes = () => {
    return (
        <Routes>
            <Route index  path='/' element={<div className='h-full'>
            <Hero/> <AboutSection/> <Numbers/> <Products/>  <Location/> <VideosSection/> <Contact/>  <Brands/> <HomeButton/></div>}/>
            <Route path='/about' element={<div className='h-full'><About /></div>} />

                <Route path="/500" element={<InternalServerError />} />
                <Route path="/*" element={<NotFound />} />
        </Routes>
    )
}

export default AppRoutes;