import React, { useState, useEffect, useContext } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { TiArrowSortedDown } from "react-icons/ti";
import { TypeAnimation } from 'react-type-animation';
import { LanguageContext } from '../Context/LanguageContext';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import logo_image from '../assets/icons/appicon.png';
import usa_icon from '../assets/flags/usa.png';
import kurdistan_icon from '../assets/flags/kurdistan.png';
import iraq_icon from '../assets/flags/iraq.png';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const [nav, setNav] = useState(false);
  const [scrollMove, setScrollMove] = useState(false);
  const [color, setColor] = useState('transparent');
  const [textColor, setTextColor] = useState('white');
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const scrollToSection = (sectionId) => {
    if (sectionId === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -100; // Adjust this value to set the margin
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      handleNav(); // Close the mobile menu after navigation
    }
  };

  useEffect(() => {
    if (path === '/') {
      setTextColor('white')
    } else {
      setTextColor('black')
    }
    const changeColor = () => {
      if (window.scrollY >= 90) {
        setColor('rgba(0, 0, 0, 0.5)');
        setTextColor('white');
      } else {
        setColor('transparent');
        if (path !== '/') {
          setTextColor('black');
        } else {
          setTextColor('white');
        }
      }
    };

    const handleScroll = () => {
      changeColor();
      setScrollMove(window.scrollY >= 90);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [path]);

  const { texts, language, changeLanguage } = useContext(LanguageContext);

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const selectLanguage = (lang) => {
    changeLanguage(lang);
    setLanguageDropdownOpen(false);
  };

  return (
    <nav
      style={{ backgroundColor: `${color}` }}
      className='fixed left-0 top-0 w-full z-50 ease-in duration-300'
    >
      <div className='max-w-[1300px] m-auto flex justify-between items-center p-4 text-white'>
        <a href='/' className={`text-lg font-bold flex justify-center items-center`} >
          <img
           className='w-6 h-6 mr-2 ml-2'
             src={logo_image}
             alt="icon"
             content='cover'
              />

          <TypeAnimation
            key={path !== '/'? textColor : texts.logo_title}
            sequence={[texts.logo_title, 2000, '']}
            wrapper="span"
            speed={50}
            style={{ color: textColor }}
            repeat={Infinity}
          />
        </a>
        <ul style={{ color: `${textColor}` }} className='hidden lg:flex items-center'>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            {path !== '/' ? <a href='/'>{texts.home}</a> : <Link onClick={() => scrollToSection('/')}>{texts.home}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            {path !== '/' ? <a href='/about'>{texts.about}</a> : <Link onClick={() => scrollToSection('about')}>{texts.about}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            {path !== '/' ? <a href='/'>{texts.products}</a> : <Link onClick={() => scrollToSection('products')}>{texts.products}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            {path !== '/' ? <a href='/'>{texts.our_videos}</a> : <Link onClick={() => scrollToSection('videos')}>{texts.our_videos}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            {path !== '/' ? <a href='/'>{texts.brands}</a> : <Link onClick={() => scrollToSection('brands')}>{texts.brands}</Link>}
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            <Link onClick={() => scrollToSection('contact')}>{texts.contact}</Link>
          </li>
          <li className='p-4 text-lg font-bold hover:text-primary'>
            <div className="relative">
              <button
                onClick={toggleLanguageDropdown}
                className="flex items-center justify-center w-full"
              >
                <div className='w-48 flex justify-center items-center'>
                  <h1 className='mr-2 ml-2'>{texts.change_language}</h1>
                  <TiArrowSortedDown className={`w-6 h-6 ${language === 'en' ? 'mt-2' : 'mt:0'}`} />
                </div>
              </button>
              {languageDropdownOpen && (
                <div className="absolute top-10 right-0 bg-white shadow-md rounded-md w-full">
                  <button onClick={() => selectLanguage('en')} className="flex justify-start items-center w-full text-left px-4 py-2 hover:bg-gray-100 text-black font-semibold">
                    <img src={usa_icon} className='w-10' alt="logo" />
                    <h1 className='mr-2 ml-2'>ENGLISH</h1>
                  </button>
                  <button onClick={() => selectLanguage('ar')} className="flex justify-start items-center w-full text-left px-4 py-2 hover:bg-gray-100 text-black font-semibold">
                    <img src={iraq_icon} className='w-10' alt="logo" />
                    <h1 className='mr-2 ml-2'>ARABIC</h1>
                  </button>
                  <button onClick={() => selectLanguage('ku')} className="flex justify-start items-center w-full text-left px-4 py-2 hover:bg-gray-100 text-black font-semibold">
                    <img src={kurdistan_icon} className='w-10' alt="logo" />
                    <h1 className='mr-2 ml-2'>KURDISH</h1>
                  </button>
                </div>
              )}
            </div>
          </li>
        </ul>

        {/* Mobile Button */}
        <div className='flex lg:hidden z-10 items-center justify-center'>
          {/* Mobile navigation button */}
          <button onClick={handleNav} className={`${language === 'en' ? 'ml-4' : 'mr-4'}`}>
            {nav ? (
              <AiOutlineClose size={32} style={{ color: `white` }} />
            ) : (
              <AiOutlineMenu
                size={32}
                style={{ color: `${textColor}` }}
              />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <div
          className={
            nav
              ? `lg:hidden absolute top-0 ${language === 'en' ? 'left-0' : 'right-0'} w-full h-screen bg-black text-center ease-in duration-300 flex justify-center items-center`
              : `lg:hidden absolute top-0 ${language === 'en' ? 'left-[-100%]' : 'right-[-100%]'} w-full h-screen bg-black text-center ease-in duration-300 flex justify-center items-center`
          }
        >
          <ul>
            <li className='p-4 text-lg font-bold hover:text-primary'>
              {path !== '/' ? <a href='/'>{texts.home}</a> : <Link onClick={() => scrollToSection('/')}>{texts.home}</Link>}
            </li>
            <li className='p-4 text-lg font-bold hover:text-primary'>
              {path !== '/' ? <a href='/about'>{texts.about}</a> : <Link onClick={() => scrollToSection('about')}>{texts.about}</Link>}
            </li>
            <li className='p-4 text-lg font-bold hover:text-primary'>
              {path !== '/' ? <a href='/'>{texts.products}</a> : <Link onClick={() => scrollToSection('products')}>{texts.products}</Link>}
            </li>
            <li className='p-4 text-lg font-bold hover:text-primary'>
              {path !== '/' ? <a href='/'>{texts.our_videos}</a> : <Link onClick={() => scrollToSection('videos')}>{texts.our_videos}</Link>}
            </li>
            <li className='p-4 text-lg font-bold hover:text-primary'>
              {path !== '/' ? <a href='/'>{texts.brands}</a> : <Link onClick={() => scrollToSection('brands')}>{texts.brands}</Link>}
            </li>
            <li className='p-4 text-lg font-bold hover:text-primary'>
              <Link onClick={() => scrollToSection('contact')}>{texts.contact}</Link>
            </li>
            <li className='p-4 text-lg font-bold hover:text-primary'>
              <div className="relative">
                <button
                  onClick={toggleLanguageDropdown}
                  className="flex items-center justify-center w-full text-gray-100"
                >
                  <div className='w-48 flex justify-center items-center'>
                    <h1 className='mr-2 ml-2'>{texts.change_language}</h1>
                    <TiArrowSortedDown className={`w-6 h-6 ${language === 'en' ? 'mt-2' : 'mt:0'}`} />
                  </div>
                </button>
                {languageDropdownOpen && (
                  <div className="absolute top-10 right-0 shadow-md rounded-md w-full text-gray-100">
                    <button onClick={() => selectLanguage('en')} className="flex justify-start items-center w-full text-left px-4 py-2 text-black bg-gray-100 font-semibold">
                      <img src={usa_icon} className='w-10' alt="logo" />
                      <h1 className='mr-2 ml-2'>ENGLISH</h1>
                    </button>
                    <button onClick={() => selectLanguage('ar')} className="flex justify-start items-center w-full text-left px-4 py-2 text-black bg-gray-100 font-semibold">
                      <img src={iraq_icon} className='w-10' alt="logo" />
                      <h1 className='mr-2 ml-2'>ARABIC</h1>
                    </button>
                    <button onClick={() => selectLanguage('ku')} className="flex justify-start items-center w-full text-left px-4 py-2 text-black bg-gray-100 font-semibold">
                      <img src={kurdistan_icon} className='w-10' alt="logo" />
                      <h1 className='mr-2 ml-2'>KURDISH</h1>
                    </button>
                  </div>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
