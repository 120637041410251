import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import { useContext } from 'react';
import 'aos/dist/aos.css';
import { useCreateResourceMutation } from '../api/apiSlice';
import ReactLoading from 'react-loading';
import { API_KEY } from './../config';



function Location() {
    const { texts,language} = useContext(LanguageContext);
    const [createResource, {error}, isLoading] = useCreateResourceMutation();


    const [data,setData] = useState([]);
  
  
   
    useEffect(() => {
      const fetchData = async () => {
        try {
         const createdData = await createResource({ url: `/get`, body: {
            "procedureName": "SPSite_Info",
            "params": `-1`,
            "lang": language == 'en' ? 3 : (language == 'ar' ? 30 : 50),
            "apiKey": API_KEY,
          } });
          
           setData(createdData?.data?.data);
  
  
        } catch (error) {
          console.log(error);
        }
      };
  
      
        fetchData();
      
    }, [language]); 
  
    if (data?.length == 0) {
      return <div className='w-full h-screen absolute bg-white flex  justify-center items-center'>
             <ReactLoading type={'bars'} height={50}  width={50} color='#12'/>
        </div>;
    }
  
    return (
        <div id='location' className='flex flex-col bg-gray-200 mt-20 m-auto items-center justify-center pt-10 text-center pr-4 pl-4'>
            <h1
                className="text-black font-bold sm:text-3xl text-lg "
                data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
            > {texts.our_location} </h1>
            <hr
                className="border-primary w-20 mt-3 mb-7"
                data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
            />


         <h1
           className="text-black font-semibold sm:text-lg text-sm mb-14"
           data-aos="fade-down"
           dangerouslySetInnerHTML={{ __html: (!error ? data[0]?.title : '') }}
         />

            <div className='w-full flex justify-center items-start  '>

            <iframe src={!error ? data[0]?.Embed:''}
            width="100%" height="450" 
           allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
    )
}

export default Location;
