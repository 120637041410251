import React, { useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import { useContext } from 'react';
import { useCreateResourceMutation } from '../api/apiSlice';
import ReactLoading from 'react-loading';
import { API_KEY } from './../config';



function About() {
  const { texts,language  } = useContext(LanguageContext);
  const [createResource, {error}, isLoading] = useCreateResourceMutation();


  const [title,setTitle] = useState('');
  const [detail,setDetail] = useState('');
  const [summary,setSummary] = useState('');
  const [photo,setPhoto] = useState('');


 
  useEffect(() => {
    const fetchData = async () => {
      try {
       const createdData = await createResource({ url: `/get`, body: {
          "procedureName": "SPSite_Info",
          "params": "-1",
          "lang": language == 'en' ? 6 : (language == 'ar' ? 25 : 41),
          "apiKey": API_KEY,
        } });
        
         setTitle(createdData?.data?.data[0].title);
         setSummary(createdData?.data?.data[0].Summary);
         setDetail(createdData?.data?.data[0].Detail);
         setPhoto(createdData?.data?.data[0].Photo);

      } catch (error) {
        console.log(error);
      }
    };


      fetchData();
    
  }, [language]); 

  if (title=='') {
    return <div className='w-full h-screen absolute bg-white flex  justify-center items-center'>
           <ReactLoading type={'bars'} height={50}  width={50} color='#12'/>
      </div>;
  }


  return (
    <div className='w-full  bg-white flex  justify-center items-center'>
<div className=''>
    <div className={'max-w-[1250px] h-[750px]  bg-cover relative mt-24 '}>

  
        <img src={`https://srwshtinistimanstorage.blob.core.windows.net/root/${photo}`} alt="About Background" 
        className={`${language=='en'?'w-full':'lg:w-[1250px]'} h-[750px] absolute left-0 right-0 top-0 bottom-0 object-cover`}  />



      <div className='absolute left-0 right-0 top-0 bottom-0 z-[1] '>
        <div className='absolute left-0 right-0 top-0 bottom-0 bg-black/50 ' />
      </div>

      <div className='max-w-[1250px] h-full flex flex-col justify-between relative z-[2]'>
        <h1 className='text-white mt-5 ml-5 mr-5 text-lg font-semibold'><a href='/'>{texts.home}</a>{' > ' + texts.about_us}</h1>

        <div className={`${language=='en'?'':'lg:w-[1250px]'} flex flex-col justify-center items-center mb-5 ml-5 mr-5`}>
          <h1 className='text-white mt-5 ml-5 text-3xl font-bold'  dangerouslySetInnerHTML={{ __html: title }}/>
          <h1 className='text-white mt-5 text-lg font-semibold text-center md:w-[50%] w-full ' dangerouslySetInnerHTML={{ __html: summary }} />
        </div>
      </div>


      
    </div>

      <div className='max-w-[1250px]  flex flex-col  items-center justify-center mt-9 ml-2 mr-2'>
        <h1 className='text-gray-700 md:text-lg font-semibold text-justify max-w-[900px] w-full mb-20 ' dangerouslySetInnerHTML={{ __html: detail }} />
      </div>

      </div>
    </div>
  );
}

export default About;
