import React, { useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from './layouts/Navbar';
import { LanguageContext } from '../src/Context/LanguageContext';
import { Footer } from './layouts/Footer';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './Routes/appRoutes';
import { useCreateResourceMutation } from './api/apiSlice';
import ReactLoading from 'react-loading';
import { API_KEY } from './config';

function App() {
  const { language } = useContext(LanguageContext);
  const [createResource, { error, isLoading }] = useCreateResourceMutation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("1", API_KEY);

        const createdData = await createResource({ url: `/get`, body: {
          "procedureName": "SPCP_MetaTags_Select",
          "params":  language === 'en' ? '2' : (language === 'ar' ? '3' : '1'),
          "lang": "",
          "apiKey": API_KEY,
        } });

        setTitle(createdData?.data?.data[0].title);
        setDescription(createdData?.data?.data[0].Description);
        
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [language]);

  if (title === '') {
    return <div className='w-full h-screen absolute bg-white flex justify-center items-center'>
           <ReactLoading type={'bars'} height={50} width={50} color='#12'/>
      </div>;
  }

  return (
    <HelmetProvider>
      <div className={`${language !== 'en' ? 'font-arfont' : ''}`} dir={`${language === 'en' ? 'ltr' : 'rtl'}`}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <BrowserRouter>
          <Navbar />
          <AppRoutes />
          <Footer />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
