import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from './Context/LanguageContext';

const InternalServerError = () => {
    const { texts } = useContext(LanguageContext);
  return (
     <div className='w-full h-[89.6vh] flex flex-col justify-center items-center'>
       <h1 className='text-black font-bold text-3xl'>500 - Internal Server Error</h1>
       <p className='text-gray-500 font-semibold text-lg mt-2'>Oops, something went wrong.</p>
       <Link to="/" className='text-gray-800 font-semibold text-lg mt-5 border px-5 py-2 rounded-sm hover:bg-primary hover:text-white'>{texts.go_back_home}</Link>
     </div>
  );
};

export default InternalServerError;
