import React, { useContext, useEffect, useRef, useState } from "react";
import { LanguageContext } from '../Context/LanguageContext';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ReactLoading from 'react-loading';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useCreateResourceMutation } from "../api/apiSlice";
import { API_KEY } from './../config';


const Hero = () => {
  const { texts, language } = useContext(LanguageContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRef = useRef(null);
  const [createResource, { error }, isLoading] = useCreateResourceMutation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const createdData = await createResource({
          url: `/get`,
          body: {
            "procedureName": "SPSite_Slider",
            "params": `3`,
            "lang": language === 'en' ? 2 : (language === 'ar' ? 3 : 1),
            "apiKey": API_KEY,
          }
        });

        setData(createdData?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [language]);

  useEffect(() => {
    if (data.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
      }, 3000); // Change the interval time (in milliseconds) as needed

      return () => clearInterval(interval);
    }
  }, [data]);

  if (data?.length === 0) {
    return <div className='w-full h-screen absolute bg-white flex justify-center items-center'>
      <ReactLoading type={'bars'} height={50} width={50} color='#12' />
    </div>;
  }

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  const changeImage = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <img
        className="w-full h-full object-cover brightness-50"
        ref={imageRef}
        src={data.length !== 0 ? `https://srwshtinistimanstorage.blob.core.windows.net/root/${data[currentIndex].Photo}` : ""}
        alt="Slideshow"
      />
      <div className="absolute inset-0 z-40 flex flex-col justify-center items-center p-2">
        <h1 className="text-white font-bold text-2xl mr-1 ml-1 text-center" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
          {data.length !== 0 ? data[currentIndex].Title1 : 'loading..'}
        </h1>

        <h1 className="text-white font-semibold mt-5 text-center" data-aos='fade-down'>
          {data.length !== 0 ? data[currentIndex].Title2 : 'loading..'}
        </h1>
        <a href="/about" className="mt-14 relative bg-primary flex justify-center items-center text-black font-bold rounded w-56 h-12 hover:bg-gray-700 hover:text-white hover:border-white hover:border" data-aos='fade-up'>
          {texts.about_us}
        </a>

        <button className="absolute md:h-10 left-4 md:top-1/2 md:bottom-1/2 bottom-1 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 rounded-full p-2 hover:bg-primary hover:rounded-sm" onClick={prevImage}>
          <IoIosArrowBack className="text-white md:text-2xl text-xl" />
        </button>
        <button className="absolute md:h-10 right-4 md:top-1/2 md:bottom-1/2 bottom-1 transform -translate-y-1/2 bg-gray-700 bg-opacity-50 rounded-full p-2 hover:bg-primary hover:rounded-sm" onClick={nextImage}>
          <IoIosArrowForward className="text-white md:text-2xl text-xl" />
        </button>
      </div>
      <div className="absolute bottom-5 left-0 right-0 text-center z-40">
        <div className="flex gap-2 justify-center">
          {data.length !== 0 ? data.map((_, index) => (
            <button
              key={index}
              onClick={() => changeImage(index)}
              className={`w-2 h-2 rounded-full bg-white ${index === currentIndex ? 'bg-opacity-100' : 'bg-opacity-50 hover:bg-opacity-100'}`}
            />
          )) : <div></div>}
        </div>
      </div>
    </div>
  );
};

export default Hero;
