import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://api.srwshtinishtiman.com/api/v1",
});



export const apiSlice = createApi({
    baseQuery,
    endpoints: (builder) => ({
      // Create
      createResource: builder.mutation({
        query: ({ url, body }) => {
          return ({
          url,
          method: 'POST',
          body:  body,
        })},
      }),
        
   
  
    //   //Read
    //   getResource: builder.query({
    //     query: (url) => url,
    //   }),
  
    //   // Update
    //   updateResource: builder.mutation({
    //     query: ({ url, body }) => ({
    //       url,
    //       method: 'PATCH',
    //       body,
    //     }),
    //   }),
  
    //   // Delete
    //   deleteResource: builder.mutation({
    //     query: (url) => ({
    //       url,
    //       method: 'DELETE',
    //     }),
    //     invalidatesTags: [{ type: 'Resource' }],
    //   }),
    }),
  });
  
  export const {
    useCreateResourceMutation
  } = apiSlice;