import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }
  

  render() {
    if (this.state.hasError) {
      return (
          <div className='w-full h-[89.6vh] flex flex-col justify-center items-center'>
           <h1 className='text-black font-bold text-3xl'>Oops, something went wrong.</h1>
           <p className='text-gray-500 font-semibold text-lg mt-2'>We're working on getting this fixed as soon as we can.</p>
           <p  className='text-gray-800 font-semibold text-lg mt-2 '>Sorry for any inconvenience.</p>
          </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
