import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../Context/LanguageContext';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useCreateResourceMutation } from '../api/apiSlice';
import ReactLoading from 'react-loading';
import { API_KEY } from './../config';



const AboutSection = () => {
  const { texts,language  } = useContext(LanguageContext);

  useEffect(() => {
    AOS.init();
  }, []);


  const [createResource, {error}, isLoading] = useCreateResourceMutation();

  const [title,setTitle] = useState('');
  const [summary,setSummary] = useState('');
  const [photo,setPhoto] = useState('');
  


 
  useEffect(() => {
    const fetchData = async () => {
      try {
       const createdData = await createResource({ url: `/get`, body: {
          "procedureName": "SPSite_Info",
          "params": "-1",
          "lang": language == 'en' ? 6 : (language == 'ar' ? 25 : 41),
          "apiKey": API_KEY,
        } });
        

         setTitle(createdData?.data?.data[0]?.title);
         setSummary(createdData?.data?.data[0]?.Summary);
         setPhoto(createdData?.data?.data[0]?.Photo);

      } catch (error) {
        console.log(error);
      }
    };

 
      fetchData();
    
  }, [language]); 


  if (title=='') {
    return <div className='w-full h-screen absolute bg-white flex  justify-center items-center'>
           <ReactLoading type={'bars'} height={50}  width={50} color='#12'/>
      </div>;
  }


  return (
    <div
      id="about"
      className="flex flex-col max-w-[1300px] m-auto items-center justify-center mt-10 mb-32  pr-4 pl-4 md:text-start text-center "
    >
      <h1
        className="text-black font-bold sm:text-3xl text-lg "
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
      >
        {title}
      </h1>
      <hr
        className="border-primary w-20 mt-3 sm:mb-16 mb-7"
        data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600"
      />

      <div className="w-full md:flex items-center justify-between">
        <div className="md:w-[45%] w-full flex flex-col items-center md:items-start">
          <h1
            className="text-black font-semibold sm:text-lg text-sm "
           data-aos='fade-down'
          >
            {summary}
          </h1>
          <a
            href="/about"
            className="mt-5 bg-primary flex justify-center items-center text-black font-bold rounded w-56 h-8 hover:bg-gray-700 hover:text-white hover:border-white hover:border"
            data-aos="fade-up" data-aos-anchor-placement="top-center"
          >
            {texts.read_more}
          </a>
        </div>
        <img
          className="md:w-[45%] w-full md:h-[450px] md:mt-0 mt-16 object-cover rounded-lg"
          src={photo!=''?`https://srwshtinistimanstorage.blob.core.windows.net/root/${photo}`:''}
          alt="icon"
          content='cover'
          data-aos='fade-down'        />
      </div>
    </div>
  );
};

export default AboutSection;
