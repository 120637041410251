import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

// Define the initial state for resources
const initialState = {
  data: [],
  status: 'idle',
  error: null
};

// Define the thunk to fetch resources
export const fetchResources = createAsyncThunk(
  'resources/fetchResources',
  async ({ query, reducerName }) => {
    const response = await apiSlice.endpoints.getResource(query).unwrap();
    return { data: response.data, reducerName };
  }
);


// Define the slice for resources
const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    // Add reducers for additional actions if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResources.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        const { data, reducerName } = action.payload;
        state.status = 'succeeded';
        state[reducerName] = data;
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  }
});

// Export actions and selectors
export const { } = resourcesSlice.actions; // No additional actions defined
export const selectAllResources = (reducerName) => (state) => state.resources[reducerName];
export const getResourcesStatus = (reducerName) => (state) => state.resources.status;
export const getResourcesError = (reducerName) => (state) => state.resources.error;

export default resourcesSlice.reducer;
